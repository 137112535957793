@import "./rdv.scss";

$orange: #EC6608;


@font-face {
  font-family: 'Orator';
  src: url('../../public/fonts/Orator-Std-Font.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body:not(.body-rdv) {
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.25;
  background-color: #F6F1EB;

  .btn {
    border-radius: 0;
  }
}

.main {
  background-color: #F6F1EB;

  .container {
    padding-top: 2rem;
  }

  .content {


    h1 {
      font-family: 'Orator';
      color: $orange;
      font-size: 1.4rem;
    }

    .separator {
      height: 0;
      width: 60px;
      border-bottom: 2px solid $orange;
      margin: 1rem auto;
    }
  }
}

.footer {
  color: $orange;
}

.text-orange {
  color: $orange;
}

.border-orange {
  border: 2px solid $orange;
  width: 80%;
  margin: 1rem auto 1.5rem;
}

a {
  color: $orange;
  text-decoration: none;
  &:hover, &:active {
    color: darken($orange, 10%);
    text-decoration: underline;
  }
}

a.text-dark {
  color: var(--bs-body-color);
  &:hover, &:active {
    color: var(--bs-body-color);
  }
}
.text-decoration-none {
  text-decoration: none;
}

.w-80px {
  width: 80px;
}



.fs-7 {
  font-size: .7rem;
}
.fs-8 {
  font-size: .65rem;
}

.legal {
  font-size: .55rem;
}

@media (min-width: 768px) {
  .px-md-6 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

form {

  .form-control {
    border: 1px solid $orange;
  }
  
  label {
    font-weight: normal;
    &.required::after {
      content: '*';
      font-size: 1rem;
      padding-left: .25rem;
      vertical-align: top;
    }
  }
  .g-recaptcha {
    margin-bottom:  30px;
    > div {
      margin: 0 auto;
    }
  }

  input.input-capitalize {
    text-transform: capitalize;
  }

  input.input-uppercase {
    text-transform: uppercase;
  }

  input.input-lowercase {
    text-transform: lowercase;
  }

  .col-phone-country, .col-phone-number {
    position: relative;
    float: left;
    min-height: 1px;
    padding-left: .75rem;
    padding-right: .75rem;
    &.col-phone-country {
      width: 37%;
      padding-right: 5px;
      select.form-control {
        padding: 6px;
      }
    }
    &.col-phone-number {
      width: 63%;
      padding-left: 5px;
    }
  }
}



// @import "../common.scss";
// @import "./rdv.scss";

// @import "../plugins/jquery.datetimepicker.scss";

// @font-face {
//   font-family: CallunaLight;
//   src: url("../../public/fonts/Calluna-Light.ttf");
// }

// body {
//   font-family: CallunaLight;
//   text-align: justify;
//   font-size: 15px;
//   // line-height: 2.5rem;
// }

// .main {
//   margin-bottom: 15px;
// }

// a:not(.btn) {
//   color: #000;
//   &:hover, &:active, &:visited {
//     color: #000;
//   }
// }

// label a {
//   text-decoration: underline;
// }

// .btn {
//   color: #fff;
//   font-weight: bold;
// }

// h1 {
//   font-size: 24px;
//   margin: 20px auto;
// }

// div.separator {
//   height: 20px;
//   width: 100%;
//   background-image: url('../../public/img/separator.png');
//   background-repeat: repeat-x;
// }

// .header {
//   padding-top: 10px;
//   img {
//     width: 90px;
//   }
// }

// .langs {
//   margin: 5px 0 10px;
// }

// .client-nav {
//   margin-bottom: 1rem;
//   .active {
//     font-weight: bold;
//   }
//   &.register-for {
//     margin-top: 4rem;
//     font-weight: bold;
//   }
// }

// small {
//   font-size: 11px;
// }

// .message {
//   margin: 2rem 0;
//   text-align: center;

//   &.message-nb {
//     margin-top: 4rem;
//     font-size: 13px;
//   }

//   a, a:hover, a:active, a:visited {
//     color: #337ab7;
//   }
//   p {
//     margin-bottom: 1.5rem;
//   }
// }

// .notfound {
//   h2 {
//     margin: 4rem auto;
//   }
// }

// .footer {
//   margin-top: 1rem;
//   font-size: 10px;
//   a.bcr {
//     margin: 0 30px;
//   }
// }

// .client-show {
//   margin-top: 2rem;
//   text-align: center;
//   .langs {
//     margin-top: 5px;
//     font-size: 10px;
//   }
//   .code {
//     margin-top: 8px;
//   }
//   .estimate {
//     margin-top: 10px;
//     img.clock {
//       width: 40px;
//       margin-right: 10px;
//     }
//     .time {
//       margin-top: 40px;
//       margin-bottom: 20px;
//       font-size: 50px;
//       .ampm {
//         font-size: 20px;
//         color: #666;
//       }
//     }
//   }
//   .details {
//     margin-top: 25px;
//     margin-bottom: 10px;
//   }
//   .form-newsletter {
//     margin: 20px 0;
//     p.intro {
//       font-weight: bold;
//     }
//   }
// }

// form {

//   label {
//     font-weight: normal;
//     &.required::after {
//       content: '*';
//       color: #C00;
//       font-size: 10px;
//       padding-left: 2px;
//       vertical-align: top;
//     }
//   }
//   .g-recaptcha {
//     margin-bottom:  30px;
//     > div {
//       margin: 0 auto;
//     }
//   }

//   input.input-capitalize {
//     text-transform: capitalize;
//   }

//   input.input-uppercase {
//     text-transform: uppercase;
//   }

//   input.input-lowercase {
//     text-transform: lowercase;
//   }


// }

// .legal {
//   margin-top: 30px;
//   font-size: 10px;
//   line-height: 11px;
//   a {
//     text-decoration: underline;
//   }
// }

// .cookies-policy {
//   width: 100%;
//   background-color: #eee;
//   padding: 10px;
//   font-size: 12px;
//   text-align: center;
// }

// .select2 {
//   .select2-selection {
//     min-height: 33px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
//     line-height: 19px;
//   }
// }
// .select2-container--default.select2-container--focus .select2-selection--multiple {
//   border: 1px solid #ccc;
// }
// .select2-container--default .select2-selection--single .select2-selection__rendered {
//   line-height: 32px;
// }
// .select2-container--default .select2-results__option--highlighted[aria-selected] {
//   background-color: #F96700;
// }

// .help-block {
//   font-size: .8rem;
// }

// // .g-recaptcha mobile
// .g-recaptcha {
//   position: relative;
//   padding-bottom: 90px;
//   padding-top: 0px;
//   height: 0;
//   iframe {
//     position: absolute;
//     top:0;
//     left: 0;
//     height: 100%;
//   }
// }
