@import "../common.scss";
@import "../plugins/jquery.datetimepicker.scss";

@font-face {
  font-family: CallunaLight;
  src: url("../../public/fonts/Calluna-Light.ttf");
}

body.body-rdv {

  font-family: CallunaLight;
  text-align: justify;
  font-size: 15px;
  background-color: #fff;
  padding-top: 150px;
  
  .main {
    margin-bottom: 15px;
  }
  
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }

  a:not(.btn) {
    color: #000;
    &:hover, &:active, &:visited {
      color: #000;
    }
  }
  
  label a {
    text-decoration: underline;
  }
  
  .btn {
    color: #fff;
    font-weight: bold;
  }
  
  h1 {
    font-size: 24px;
    margin: 20px auto;
  }
  
  div.separator {
    height: 20px;
    width: 100%;
    background-image: url('../../public/img/separator.png');
    background-repeat: repeat-x;
  }
  
  .header {
    padding-top: 10px;
    img {
      width: 90px;
    }
  }
  
  .langs {
    margin: 5px 0 10px;
  }
  
  .client-nav {
    margin-bottom: 1rem;
    .active {
      font-weight: bold;
    }
    &.register-for {
      margin-top: 4rem;
      font-weight: bold;
    }
  }
  
  small {
    font-size: 11px;
  }
  
  .message {
    margin: 2rem 0;
    text-align: center;
  
    &.message-nb {
      margin-top: 4rem;
      font-size: 13px;
    }
  
    a, a:hover, a:active, a:visited {
      color: #337ab7;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }
  
  .notfound {
    h2 {
      margin: 4rem auto;
    }
  }
  
  .footer {
    margin-top: 1rem;
    font-size: 10px;
    a.bcr {
      margin: 0 30px;
    }
  }

  form {

    label {
      font-weight: normal;
      &.required::after {
        content: '*';
        color: #C00;
        font-size: 10px;
        padding-left: 2px;
        vertical-align: top;
      }
    }
    .g-recaptcha {
      margin-bottom:  30px;
      > div {
        margin: 0 auto;
      }
    }
  
    input.input-capitalize {
      text-transform: capitalize;
    }
  
    input.input-uppercase {
      text-transform: uppercase;
    }
  
    input.input-lowercase {
      text-transform: lowercase;
    }
  
    .col-phone-country, .col-phone-number {
      position: relative;
      float: left;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      &.col-phone-country {
        width: 37%;
        padding-right: 5px;
        select.form-control {
          padding: 6px;
        }
      }
      &.col-phone-number {
        width: 63%;
        padding-left: 5px;
      }
    }
  }
  
  .legal {
    margin-top: 30px;
    font-size: 10px;
    line-height: 11px;
    a {
      text-decoration: underline;
    }
  }
  
  .cookies-policy {
    width: 100%;
    background-color: #eee;
    padding: 10px;
    font-size: 12px;
    text-align: center;
  }
  
  .select2 {
    .select2-selection {
      min-height: 33px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      line-height: 19px;
    }
  }
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border: 1px solid #ccc;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #F96700;
  }
  
  .help-block {
    font-size: .8rem;
  }
  
  // .g-recaptcha mobile
  .g-recaptcha {
    position: relative;
    padding-bottom: 90px;
    padding-top: 0px;
    height: 0;
    iframe {
      position: absolute;
      top:0;
      left: 0;
      height: 100%;
    }
  }
  
  .btn-slot {
    display: inline-block;
    border: 1px solid #F96700;
    color: #F96700;
    background-color: #fff;
    padding: 6px 8px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    &.active, &:hover {
      background-color: #F96700;
      color: #fff;
      text-decoration: none;
    }
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    border: 1px solid #F96700;
    background-color: #F96700;
    color: #fff;
    .select2-selection__choice__remove {
      color: #fff;
    }
  }

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
    color: #F96700;
  }
  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current {
    background-color: #F96700;
    box-shadow: #F96700 0 1px 3px 0 inset;
    color: #fff;
  }

}