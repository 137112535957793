
body {
  background-color: #fff;
  color: #404040;
  -webkit-font-smoothing: antialiased;
}
table {
  background-color: #fff;
}
form.form-nomargin {
  margin-bottom: 0;
}
.timeline {
  font-size:8px;
}
.footer {
  min-height: 200px;
}

table:not(.datatable) {
  th {
    position: relative;
    padding-right: 30px;
    .table-sort-asc, .table-sort-desc {
      position: absolute;
      display: block;
      opacity: 0.125;
      right: 10px;
      line-height: 9px;
      font-size: 0.8em;
      cursor: pointer;
    }
    .table-sort-asc {
      bottom: 50%;
    }
    .table-sort-desc {
      top: 50%;
    }
    .active {
      opacity: 0.6;
    }
  }
}
